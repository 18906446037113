import React, { useState, useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Certifications.scss";

const Certifications = () => {
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "certifications"]';

    client.fetch(query).then((data) => {
      setCerts(data);
    });
  }, []);

  return (
    <React.Fragment>
      <h2 className="head-text">Certifications</h2>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__certification-portfolio">
        {certs.map((Certification, index) => (
          <div className="app__certification-item app__flex" key={index}>
            <div className="app__certification-img app__flex">
              <img
                src={urlFor(Certification.imgUrl)}
                alt={Certification.name}
              />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__certification-hover app__flex">
                <a
                  href={Certification.certificateLink}
                  target="_blank"
                  rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex">
                    <AiFillEye />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__certification-content app__flex">
              <h4 className="bold-text">{Certification.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {Certification.description}
              </p>
            </div>
          </div>
        ))}
      </motion.div>
    </React.Fragment>
  );
};

export default AppWrap(
  MotionWrap(Certifications, "app__certifications"),
  "certifications",
  "app__whitebg"
);
