import email from "../assets/email.png";
import api from "../assets/api.png";
import css from "../assets/css.png";
import git from "../assets/git.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.svg";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import github from "../assets/github.png";
import github_icon from "../assets/github_icon.png";
import csharp from "../assets/csharp.png";
import sql from "../assets/sql-server.png";
import linkedin from "../assets/linkedin.png";

import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

export default {
  email,
  api,
  css,
  git,
  html,
  javascript,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  github,
  github_icon,
  linkedin,
  circle,
  logo,
  csharp,
  sql,
};
