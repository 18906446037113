import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Experiences.scss";

const Experiences = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"] | order(start desc)';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Work Experiences</h2>

      <div className="app__skills-container">
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.company}>
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-exp-work">
                <h4
                  className="bold-text"
                  style={{ textDecoration: "underline" }}>
                  {experience.title}
                </h4>
                <p className="bold-text">{experience.year}</p>
              </motion.div>
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-exp-desc">
                <h4 className="bold-text">{experience.company}</h4>
                <p className="p-text">{experience.desc}</p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experiences, "app__skills"),
  "experiences",
  "app__primarybg"
);
