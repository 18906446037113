import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => (
  <>
    <div className="app__about">
      <h2 className="head-text">
        Hi, I am <span>Kevin Lim</span>
        <br />A <span>Fullstack developer</span>
      </h2>
      <p className="app__header-p">
        I am on a mission to blend creativity with functionality.
      </p>
      <p className="app__header-p">Let's build something amazing together!</p>
    </div>
    <div className="app__orbit">
      <ul className="app__orbit-wrap">
        <li>
          <ul className="ring-0">
            <li>
              <img
                className="app__orbit-icon"
                src={images.python}
                alt="python"
              />
            </li>
            <li>
              <img
                className="app__orbit-icon"
                src={images.csharp}
                alt="github"
              />
            </li>
            <li>
              <img className="app__orbit-icon" src={images.git} alt="git" />
            </li>
            <li>
              <img className="app__orbit-icon" src={images.sql} alt="sql" />
            </li>
          </ul>
        </li>

        <li>
          <ul className="ring-1">
            <li>
              <img className="app__orbit-icon" src={images.node} alt="node" />
            </li>
            <li>
              <img className="app__orbit-icon" src={images.css} alt="css" />
            </li>
            <li>
              <img className="app__orbit-icon" src={images.html} alt="html" />
            </li>
          </ul>
        </li>
        <li>
          <ul className="ring-2">
            <li>
              <img className="app__orbit-icon" src={images.react} alt="react" />
            </li>
            <li>
              <img
                className="app__orbit-icon"
                src={images.javascript}
                alt="javascript"
              />
            </li>
            <li>
              <img
                className="app__orbit-icon"
                src={images.typescript}
                alt="typescript"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </>
);

export default AppWrap(Header, "home");
